import React from "react";

import { Link, FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import { navigate } from "gatsby";

import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";

const NavSimple = () => {
  return (
    <nav className="absolute top-0 z-50 w-screen ">
      <div className="w-full lg:w-1220 mx-auto px-4">
        <div className="relative w-full flex items-center justify-between h-20">
          <div
            className="flex-shrink-0 flex items-center text-sm md:text-base cursor-pointer"
            role="button"
            onClick={() => {
              navigate(-1);
            }}
            onKeyDown={() => {
              navigate(-1);
            }}
            tabIndex={0}
          >
            <span className="text-xs md:text-sm font-light mr-1">
              <FaChevronLeft />{" "}
            </span>{" "}
            <FormattedMessage id="nav_back" />
          </div>
          <Link to="/" className="flex-shrink-0 flex items-center mr-4">
            <StaticImage
              src="../../images/reel/reel_logo_black.png"
              alt="reel logo"
              placeholder="blurred"
              layout="constrained"
              loading="eager"
              height={12}
            />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavSimple;
