import React from "react";
import LayoutSimple from "../components/layout/layoutSimple";
import Seo from "../components/layout/seo";
import { Helmet } from "react-helmet";

const ContactEn = () => {
  return (
    <LayoutSimple>
      <Seo title="Contact" />
      <Helmet>
        <script type="text/javascript">
          {`
                        window.Reform=window.Reform||function(){(Reform.q=Reform.q||[]).push(arguments)}
                    `}
        </script>
        <script
          id="reform-script"
          async="true"
          src="https://embed.reform.app/v1/embed.js"
        ></script>

        <script type="text/javascript">
          {`
              Reform('init', {
                url: 'https://forms.reform.app/reel/contact-dk',
                  target: '#contact-form',
              })
          `}
        </script>
      </Helmet>
      <div className="flex flex-col min-h-[100vh] min-w-[100vw]">
        <div id="contact-form" className="mt-14 z-20 relative"></div>
        <div className="top-40 left-[50%] -ml-8 absolute z-10">
          <div className="loading-spinner rounded-full border-4 border-t-4 border-gray-200 h-16 w-16"></div>
        </div>
      </div>
    </LayoutSimple>
  );
};
export default ContactEn;
