import React from "react";
import NavSimple from "./navSimple";

const LayoutSimple = ({ children, isLight }) => {
  return (
    <div className="w-screen relative overflow-hidden">
      <NavSimple></NavSimple>
      <main className="relative">{children}</main>
    </div>
  );
};

export default LayoutSimple;
